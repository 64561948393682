import React from "react";
import Home from "./Home";
import Footer from "./Footer";
import Ban from "./Ban";
// import { Routes, Route } from "react-router-dom";

export default function Movies() {
  return (
    <>
      <Home
        imd1="8.5"
        link1="/DayswithMyStepsisterSeason1HindiDubbed"
        img1="https://i.postimg.cc/43Nw5KDT/MV5-BZDU2-MWNi-YWMt-Mj-Ix-ZS00-ZTVl-LTkx-ZDAt-Yz-A5-Yz-Fh-ODBh-ODAy-Xk-Ey-Xk-Fqc-Gc-V1-FMjpg-UX1000.jpg"
        name1="Days with My Stepsister Season 1 Hindi Dubbed"
        // 2 start
        imd2="1.0"
        link2="/TwilightOutofFocusSeason1HindiDubbed"
        img2="https://i.postimg.cc/dtV554Wf/MV5-BOTZh-ZWQ3-ZGUt-Zj-M2-NC00-OTVi-LWI3-Mz-It-OWJk-Nz-Fk-Y2-Zh-Mzcw-Xk-Ey-Xk-Fqc-Gc-V1-FMjpg-UX1000.jpg"
        name2="Twilight Out of Focus Season 1 Hindi Dubbed"
        // 3 start
        imd3="7.5"
        link3="/TheStrongestMagicianintheDemonLord’sArmyWasaHumanSeason1HindiDubbed"
        img3="https://i.postimg.cc/cJf7xb1X/maougun-KV-1452x2048.png"
        name3="The Strongest Magician in the Demon Lord’s Army Was a Human Season 1"
        // 4 start
        imd4="1.0"
        link4="/TrueBeautySeason1HindiDubbed"
        img4="https://i.postimg.cc/mDKWRkmv/true-beauty-snwt-960.webp"
        name4="True Beauty Season 1"
      />

      <Home
        imd1="1.0"
        link1="/AlyaSometimesHidesHerFeelingsinRussian"
        img1="https://i.postimg.cc/9FmvSM5J/MV5-BNjg0-YTVj-NTIt-ZTI1-ZC00-MDZl-LTll-Yj-Qt-MDY0-ODMx-NDdl-Yj-Y3-Xk-Ey-Xk-Fqc-Gc-V1-FMjpg-UX1000.jpg"
        name1="Alya Sometimes Hides Her Feelings in Russian Season 1"
        // 2 start
        imd2="1.0"
        link2="/NoLongerAllowedinAnotherWorldSeason1HindiDubbed"
        img2="https://i.postimg.cc/cH5nSCkw/season-1.jpg"
        name2="No Longer Allowed in Another World Season 1"
        // 3 start
        imd3="1.0"
        link3="/LogHorizonSeason1HindiDubbed"
        img3="https://i.postimg.cc/SRMJbrMb/x-LGd-Ru-WCn2-Al-By-L5-Y9-OLB3-Zw-Itg.jpg"
        name3="Log Horizon Season 2"
        // 4 start
        imd4="1.0"
        link4="/VTuberLegend:HowIWentViralAfterForgettingtoTurnOffMyStreamHindiDubbed"
        img4="https://i.postimg.cc/t42FX9Tq/OIP.jpg"
        name4="VTuber Legend: How I Went Viral After Forgetting to Turn Off My Stream"
      />
<Ban
  ban="https://i.postimg.cc/8CXPHkLs/84affd77-f1db-4ca2-b705-2e29f0368ad9.jpg"
  link="/WhyDoesNobodyRememberMeInThisWorldHindiDubbed"
/>

      <Home
        imd1="1.0"
        link1="/Wistoria:WandandSwordHindiDubbed"
        img1="https://i.postimg.cc/1tHQd7Bj/5n0-IZQA9-Mi-Xn5-JBKZibt-Nhh-FSAZ.jpg"
        name1="Wistoria: Wand and Sword"
        // 2 start
        imd2="1.0"
        link2="/TowerofGodseason2HindiDubbed"
        img2="https://i.postimg.cc/8Cm9kJvF/og-image.webp"
        name2="Tower of God"
        // 3 start
        imd3="1.0"
        link3="/WhyDoesNobodyRememberMeInThisWorldHindiDubbed"
        img3="https://i.postimg.cc/DZDtRVs5/season-1-1.jpg"
        name3="Why Does Nobody Remember Me In This World"
        // 4 start
        imd4="1.0"
        link4="/Makeine:TooManyLosingHeroines!HindiDubbed"
        img4="https://i.postimg.cc/wjT05fRB/OIP-1.jpg"
        name4="Makeine: Too Many Losing Heroines!"
      />


      <Home
        imd1="1.0"
        link1="/ByeBye,Earth"
        img1="https://i.postimg.cc/qRfFGcdZ/bye-bye-earth.jpg"
        name1="Bye Bye, Earth"
        // 2 start
        imd2="1.0"
        link2="/QualityAssuranceinAnotherWorldHindiDubbed"
        img2="https://i.postimg.cc/pTxZTsvx/anime-quality-assurance-in-another-world-visuel-2.avif"
        name2="Quality Assurance in Another World"
        // 3 start
        imd3="1.0"
        link3="/AssassinationClassroomSeason2"
        img3="https://i.postimg.cc/8z6Bm2PN/o-Te-MBICUAL2d-JVd-B11-B45y-TCGg5.jpg"
        name3="Assassination Classroom Season 2"
        // 4 start
        imd4="1.0"
        link4="/FairyTail:100YearsQuest"
        img4="https://i.postimg.cc/Mpm0LyRF/4453c15bb8b93115a72886112afbe087247318588beaf0e46539aa55a9bb28a7-RI-TTW.jpg"
        name4="Fairy Tail: 100 Years Quest"
      /> 

<Ban
        ban="https://i.postimg.cc/Dz3tPxqY/6aefc461-5788-448a-a2b9-ad3eae0e3d32.png"
        link="/AlyaSometimesHidesHerFeelingsinRussian"
      />

      <Home
        imd1="1.0"
        link1="/Dr.STONESeason2"
        img1="https://i.postimg.cc/8CT4kyNG/OIP-2.jpg"
        name1="Dr. STONE Season 2"
        // 2 start
        imd2="1.0"
        link2="/ViralHitSeason1HindiDubbed"
        img2="https://i.postimg.cc/dQgzQKXK/1284207.jpg"
        name2="Viral Hit Season 1"
        // 3 start
        imd3="1.0"
        link3="/Haikyu!!Season1HindiDubbed"
        img3="https://i.postimg.cc/02gBR0Mk/1-Rfa9o5-H6-Zl-Xzb-DVNlw4j-ULd-Xpi.jpg"
        name3="Haikyu!! Season 1"
        // 4 start
        imd4="1.0"
        link4="/BlueLockSeason1"
        img4="https://i.postimg.cc/YqsjCj7C/E85-Ab-Dw-Vg-AQV5-AV.jpg"
        name4="BLUE LOCK Season 1"
      />

      

      <Home
        imd1="1.0"
        link1="/KaijuNo.8Season1HindiDubbed"
        img1="https://i.postimg.cc/q7RThxGf/c1-T8-UHw-Caq6-Je-QYgt4-U8-ERPGox-N.jpg"
        name1="Kaiju No. 8 Season 1"
        // 2 start
        imd2="1.0"
        link2="/SoloLevelingSeason1HindiDubbed"
        img2="https://i.postimg.cc/MZs116wh/75c-ENUBSmn-ON8-IBDc2-F979-CMus-N.jpg"
        name2="Solo Leveling Season 1"
        // 3 start
        imd3="1.0"
        link3="/AssassintionClassroomSeason1"
        img3="https://i.postimg.cc/vBJmH649/images.jpg"
        name3="Assassination Classroom"
        // 4 start
        imd4="1.0"
        link4="/DemonSlayerSeason2"
        img4="https://images.justwatch.com/poster/256743734/s718/season-2.jpg"
        name4="Demon Slayer Season 2"
      />

      <Footer />
    </>

  );
}