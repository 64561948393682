const data = [
  {
    id: 1,
    name: "/",
    link: "/",
  },
  {
    id: 2,
    name: "Assassintion Classroom season 1",
    link: "/AssassintionClassroomseason1",
  },
  {
    id: 3,
    name: "Alya Sometimes Hides Her Feelings in Russian",
    link: "/AlyaSometimesHidesHerFeelingsinRussian",
  },
  {
    id: 4,
    name: "Assassination Classroom season 2",
    link: "/AssassinationClassroomseason2",
  },
  {
    id: 5,
    name: "Bye Bye, Earth",
    link: "/ByeBye,Earth",
  },
  {
    id: 6,
    name: "Days with My Stepsister Season 1 Hindi Dubbed",
    link: "/DayswithMyStepsisterSeason1HindiDubbed",
  },
  {
    id: 7,
    name: "Dr. STONE Season 2",
    link: "/Dr.STONESeason2",
  },
  {
    id: 8,
    name: "Fairy Tail: 100 Years Quest",
    link: "/FairyTail:100YearsQuest",
  },
  {
    id: 9,
    name: "Haikyu!! Season 1 Hindi Dubbed",
    link: "/Haikyu!!Season1HindiDubbed",
  },
  {
    id: 10,
    name: "Kaiju No. 8 Season 1 Hindi Dubbed",
    link: "/KaijuNo.8Season1HindiDubbed",
  },
  {
    id: 11,
    name: "Log Horizon Season 1 Hindi Dubbed",
    link: "/LogHorizonSeason1HindiDubbed",
  },
  {
    id: 12,
    name: "Makeine: Too Many Losing Heroines! Hindi Dubbed",
    link: "/Makeine:TooManyLosingHeroines!HindiDubbed",
  },
  {
    id: 13,
    name: "No Longer Allowed in Another World Season 1 Hindi Dubbed",
    link: "/NoLongerAllowedinAnotherWorldSeason1HindiDubbed",
  },
  {
    id: 14,
    name: "Quality Assurance in Another World Hindi Dubbed",
    link: "/QualityAssuranceinAnotherWorldHindiDubbed",
  },
  {
    id: 15,
    name: "Solo Leveling Season 1 Hindi Dubbed",
    link: "/SoloLevelingSeason1HindiDubbed",
  },
  {
    id: 16,
    name: "The Strongest Magician in the Demon Lord’s Army Was a Human Season 1 Hindi Dubbed",
    link: "/TheStrongestMagicianintheDemonLord’sArmyWasaHumanSeason1HindiDubbed",
  },
  {
    id: 17,
    name: "Tower of God season 2 Hindi Dubbed",
    link: "/TowerofGodseason2HindiDubbed",
  },
  {
    id: 19,
    name: "True Beauty Season 1 Hindi Dubbed",
    link: "/TrueBeautySeason1HindiDubbed",
  },
  {
    id: 20,
    name: "Twilight Out of Focus Season 1 Hindi Dubbed",
    link: "/TwilightOutofFocusSeason1HindiDubbed",
  },
  {
    id: 21,
    name: "Viral Hit Season 1 Hindi Dubbed",
    link: "/ViralHitSeason1HindiDubbed",
  },
  {
    id: 22,
    name: "VTuber Legend: How I Went Viral After Forgetting to Turn Off My Stream Hindi Dubbed",
    link: "/VTuberLegend:HowIWentViralAfterForgettingtoTurnOffMyStreamHindiDubbed",
  },
  {
    id: 23,
    name: "Why Does Nobody Remember Me In This World Hindi Dubbed",
    link: "/WhyDoesNobodyRememberMeInThisWorldHindiDubbed",
  },
  {
    id: 24,
    name: "Wistoria: Wand and Sword Hindi Dubbed",
    link: "/Wistoria:WandandSwordHindiDubbed",
  },
  {
    id: 25,
    name: "demon slayer season 2",
    link: "/demonslayerseason2",
  },
  {
    id: 26,
    name: "Horimiya: The Missing Pieces premiered",
    link: "/Horimiya:TheMissingPiecespremiered",
  },
  {
    id: 27,
    name: "Jujutsu kaisen season 2 Hindi Dubbed",
    link: "/Jujutsukaisenseason2HindiDubbed",
  },
  {
    id: 27,
    name: "Jujutsu kaisen season 1 Hindi Dubbed",
    link: "/Jujutsukaisenseason1HindiDubbed",
  },
  {
    id: 28,
    name: "spy x family season 2 hindi dubbed",
    link: "/spyxfamilyseason2hindidubbed",
  },
  {
    id: 29,
    name: "Mushoku Tensei Jobless Reincarnation",
    link: "/MushokuTenseiJoblessReincarnation",
  },
  {
    id: 30,
    name: "Demon Slayer season 3 hindi dubbed",
    link: "/DemonSlayerseason3hindidubbed",
  },
  {
    id: 31,
    name: "Demon Slayer season 4 hindi dubbed",
    link: "/DemonSlayerseason4hindidubbed",
  },
  {
    id: 32,
    name: "An Archdemon's Dilemma: How to Love Your Elf Bride Season 1",
    link: "/AnArchdemon'sDilemma:HowtoLoveYourElfBrideSeason1",
  },
  {
    id: 34,
    name: "Mashle : Magic and Muscles Season 2",
    link: "/Mashle:MagicandMusclesSeason2",
  },
  {
    id: 35,
    name: "naruto season 1 hindi dubbed",
    link: "/narutoseason1hindidubbed",
  },
  {
    id: 36,
    name: "Wind Breaker season 1 hindi dubbed",
    link: "/WindBreakerseason1hindidubbed",
  },
  {
    id: 37,
    name: "Ranking of Kings season 1",
    link: "/RankingofKingsseason1",
  },
  {
    id: 38,
    name: "Akebi's Sailor Uniform season 1",
    link: "/Akebi'sSailorUniformseason1",
  },
  {
    id: 39,
    name: "Black Rock Shooter: Dawn Fall season 1",
    link: "/BlackRockShooter:DawnFallseason1",
  },
  {
    id: 40,
    name: "one punch man season 1 hindi dubbed",
    link: "/onepunchmanseasone1hindidubbed",
  },
  {
    id: 41,
    name: "Chainsaw Man season 1 hindi dubbed",
    link: "/ChainsawManseason1hindidubbed",
  },
  {
    id: 42,
    name: "I Got a Cheat Skill in Another World",
    link: "/IGotaCheatSkillinAnotherWorld",
  },
  {
    id: 43,
    name: "",
    link: "/",
  },
  {
    id: 44,
    name: "",
    link: "/",
  },
  {
    id: 45,
    name: "",
    link: "/",
  },
  {
    id: 46,
    name: "",
    link: "/",
  },
  {
    id: 47,
    name: "",
    link: "/",
  },
  {
    id: 48,
    name: "",
    link: "/",
  },
  {
    id: 49,
    name: "",
    link: "/",
  },
  {
    id: 50,
    name: "",
    link: "/",
  },
];

export default data;
