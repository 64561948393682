import React from "react";
export default function Animeover() {
  return (
    <>
    <div className="flex justify-center">
   <div className="ep-over flex items-center justify-center bg-[rgb(11,11,65)] h-20 w-[1276px] mt-1 rounded-b-lg border-none">
  <p>
    <b className="text-white font-bold text-lg"> Episode Over 😊</b>
  </p>
</div>
</div>

    </>
  );
}
