import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./components/About";
import Navbar from "./components/Navbar";
import Movies from "./components/Movies";
import SearchBar from "./components/SearchBar";
import ScrollUpButton from "./components/ScrollUpButton";
//  Anime are import  

import Jjk from "./Anime/Jjk";
import Mash from "./Anime/Mash"
import Bluelock from "./Anime/Bluelock"
import Assassintionclasss1 from "./Anime/Assassintionclasss1"
import Assassintionclasss2 from "./Anime/Assassintionclasss2";
import Byebye from "./Anime/Byebye";
import Dayswithmyst from "./Anime/Dayswithmyst";
import Drstone from "./Anime/Drstone";
import Fairytail from "./Anime/Fairytail";
import Haikyu from "./Anime/Haikyu";
import Kaijuno8 from "./Anime/Kaijuno8";
import Loghorizon from "./Anime/Loghorizon";
import Makeine from "./Anime/Makeine";
import Nolonger from "./Anime/Nolonger";
import Quality from "./Anime/Quality";
import Sololeveling from "./Anime/Sololeveling";
import Thestrongestma from "./Anime/Thestrongestma";
import Towerofgod from "./Anime/Towerofgod";
// import Towerofgods2 from "./Anime/Towerofgods2";
import Turebeauty from "./Anime/Turebeauty";
import Twilinghtout from "./Anime/Twilinghtout";
import Viral from "./Anime/Viral";
import Vtuberlegend from "./Anime/Vtuberlegend";
import Whydoes from "./Anime/Whydoes";
import Wistoria from "./Anime/Wistoria";
import Drass from "./Anime/Drass";
import Alya from "./Anime/Alya";
import Horim from "./Anime/Horim";
import Jjk2 from "./Anime/Jjk2";
import AnimeMovies from "./components/AnimeMovies";
import Spy from "./Anime/Spy";
import Musoko from "./Anime/Musoko";
import Demon3 from "./Anime/Demon3";
import Demon4 from "./Anime/Demon4";
import Anarchdemon from "./Anime/Anarchdemon";
import Naruto from "./Anime/Naruto";
import Wind from "./Anime/Wind";
import Rankof from "./Anime/Rankof";
import Akedi from "./Anime/Akedi";
import Blackrock from "./Anime/Blackrock";
import Onepunch from "./Anime/Onepunch";
import Tell from "./Structure/Tell";
import Chanso from "./Anime/Chanso";
import Igotchat from "./Anime/Igotchat";







function App() {
  return (
    <>
      <Navbar />
      <SearchBar />
      <Tell />

      <ScrollUpButton />

      <Routes>
        <Route exact path="/" element={<Movies />} />
        <Route exact path="/home" element={<Movies />} />
        <Route exact path="/movies" element={<AnimeMovies />} />
        <Route exact path="/about" element={<About />} />
        {/* Anime Movies link start 😃🧐 */}



        <Route exact path="/Mashle:MagicandMusclesSeason2" element={<Mash />} />
        <Route exact path="/BLUELOCKSeason1" element={<Bluelock />} />
        <Route exact path="/AssassintionClassroomseason1" element={<Assassintionclasss1 />} />
        <Route exact path="/AssassinationClassroomseason2" element={<Assassintionclasss2 />} />
        <Route exact path="/AlyaSometimesHidesHerFeelingsinRussian" element={<Alya />} />
        <Route exact path="/ByeBye,Earth" element={<Byebye />} />
        <Route exact path="/DayswithMyStepsisterSeason1HindiDubbed" element={<Dayswithmyst />} />
        <Route exact path="/Dr.STONESeason2" element={<Drstone />} />
        <Route exact path="/FairyTail:100YearsQuest" element={<Fairytail />} />
        <Route exact path="/Haikyu!!Season1HindiDubbed" element={<Haikyu />} />
        <Route exact path="/KaijuNo.8Season1HindiDubbed" element={<Kaijuno8 />} />
        <Route exact path="/LogHorizonSeason1HindiDubbed" element={<Loghorizon />} />
        <Route exact path="/Makeine:TooManyLosingHeroines!HindiDubbed" element={<Makeine />} />
        <Route exact path="/NoLongerAllowedinAnotherWorldSeason1HindiDubbed" element={<Nolonger />} />
        <Route exact path="/QualityAssuranceinAnotherWorldHindiDubbed" element={<Quality />} />
        <Route exact path="/SoloLevelingSeason1HindiDubbed" element={<Sololeveling />} />
        <Route exact path="/TheStrongestMagicianintheDemonLord’sArmyWasaHumanSeason1HindiDubbed" element={<Thestrongestma />} />
        <Route exact path="/TowerofGodseason2HindiDubbed" element={<Towerofgod />} />
        {/* <Route exact path="/TowerofGodSeason2HindiDubbed" element={<Towerofgods2/>}/> */}
        <Route exact path="/TrueBeautySeason1HindiDubbed" element={<Turebeauty />} />
        <Route exact path="/TwilightOutofFocusSeason1HindiDubbed" element={<Twilinghtout />} />
        <Route exact path="/ViralHitSeason1HindiDubbed" element={<Viral />} />
        <Route exact path="/VTuberLegend:HowIWentViralAfterForgettingtoTurnOffMyStreamHindiDubbed" element={<Vtuberlegend />} />
        <Route exact path="/WhyDoesNobodyRememberMeinThisWorldHindiDubbed" element={<Whydoes />} />
        <Route exact path="/Wistoria:WandandSwordHindiDubbed" element={<Wistoria />} />
        <Route exact path="/demonslayerseason2" element={<Drass />} />
        <Route exact path="/Horimiya:TheMissingPiecespremiered" element={<Horim />} />
        <Route exact path="/Jujutsukaisenseason2HindiDubbed" element={<Jjk />} />
        <Route exact path="/Jujutsukaisenseason1HindiDubbed" element={<Jjk2 />} />
        <Route exact path="/spyxfamilyseason2hindidubbed" element={<Spy />} />
        <Route exact path="/MushokuTenseiJoblessReincarnation" element={<Musoko />} />
        <Route exact path="/DemonSlayerseason3hindidubbed" element={<Demon3 />} />
        <Route exact path="/DemonSlayerseason4hindidubbed" element={<Demon4 />} />
        <Route exact path="/AnArchdemon'sDilemma:HowtoLoveYourElfBrideSeason1" element={<Anarchdemon />} />
        <Route path="/Mashle:MagicandMusclesSeason2" element={<Mash />} />
        <Route exact path="/narutoseason1hindidubbed" element={<Naruto />} />
        <Route exact path="/WindBreakerseason1hindidubbed" element={<Wind />} />
        <Route exact path="/RankingofKingsseason1" element={<Rankof />} />
        <Route exact path="/Akebi'sSailorUniformseason1" element={<Akedi />} />
        <Route exact path="/BlackRockShooter:DawnFallseason1" element={<Blackrock />} />
        <Route exact path="/onepunchmanseasone1hindidubbed" element={<Onepunch />} />
        <Route exact path="/ChainsawManseason1hindidubbed" element={<Chanso />} />
        <Route exact path="/IGotaCheatSkillinAnotherWorld" element={<Igotchat />} />

        {/* <Route exact path="/" element={< />}     /> */}

      </Routes>


 
    </>
  );
     // RedirectToPrerender Component
const RedirectToPrerender = ({ url }) => {
window.location.href = `/.netlify/functions/prerender/${url}`;
return null; // Prevent rendering
}; 

}

export default App;
